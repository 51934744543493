import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Img1 from "../images/MicrosoftTeams-image (6). (1).webp";
import Img2 from "../images/7. (1).webp";
import Img3 from "../images/viacarte.png";
import Img4 from "../images/zokudo.png";
import Img5 from "../images/rbp.png";
import Img6 from "../images/findypay.png";
import Img7 from "../images/mego.png";
import Img8 from "../images/MicrosoftTeams-image (4). (1).webp";
import Img9 from "../images/8. (1).webp";

const Home = () => {
  useEffect(() => {
    document.title = "Home - NDDEO";
  }, []);
  return (
    <div className="home">
      <div className="home1">
        <div className="part1">
          <div className="text1">
            Software solutions for seamless payment aggregation
          </div>
          <div className="text2">
            Custom solutions enabling you to achieve true potential of payment
            aggregation.
          </div>
        </div>
        <div className="part2">
          <img className="img" src={Img1} alt="" />
        </div>
      </div>
      <div className="home2">
        <div className="text">Learn how our custom solutions can help you</div>
        <div>
          <Link to="/contact" type="button" className="btn btn-primary">
            SCHEDULE A CALL
          </Link>
        </div>
      </div>
      <div className="home3">
        <div className="part2">
          <img className="img" src={Img2} alt="" />
        </div>
        <div className="part1">
          <div className="text1">Services that help you stay ahead</div>
          <div className="text2">
            Integrated services designed for the need of your business.
          </div>
          <div>
            <Link to="/services" type="button" className="btn btn-primary">
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>
      <div className="home4">
        <div className="text1">Trusted by the industry leaders</div>
        <div className="box">
          <div className="b1">
            <img className="industry" src={Img3} alt="" />
          </div>
          <div className="b1">
            <img className="industry" src={Img4} alt="" />
          </div>
          <div className="b1">
            <img className="industry" src={Img5} alt="" />
          </div>
          <div className="b1">
            <img className="industry" src={Img6} alt="" />
          </div>
          <div className="b1">
            <img className="industry" src={Img7} alt="" />
          </div>
        </div>
      </div>
      <div className="home5">
        <div className="part1">
          <div className="text1">Career that's more than just a job</div>
          <div className="text2">
            Find our work interesting? Here's how you can join us.
          </div>
          <div>
            <Link to="/careers" type="button" className="btn btn-primary">
              LEARN MORE
            </Link>
          </div>
        </div>
        <div className="part2">
          <img className="img" src={Img8} alt="" />
        </div>
      </div>
      <div className="home6">
        <div className="part2">
          <img className="img" src={Img9} alt="" />
        </div>
        <div className="part1">
          <div className="text1">We're here</div>
          <div className="text2">
            Let's connect & explore how we can help you.
          </div>
          <div>
            <Link to="/contact" type="button" className="btn btn-primary">
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
