import React, { useEffect } from "react";
import img1 from "../images/1. (1).webp";
import img2 from "../images/2. (1).webp";
import img3 from "../images/3. (1).webp";
import img4 from "../images/4. (1).webp";
import img5 from "../images/55. (1).webp";
import { Link } from "react-router-dom";

const Services = () => {
  useEffect(() => {
    document.title = "Our Services - NDDEO";
  }, []);
  return (
    <div className="services">
      <div className="p1">
        <div className="heading">Solutions created for your need</div>
        <div id="carouselExample" class="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={img1} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={img2} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={img3} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={img4} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={img5} className="d-block w-100" alt="..." />
            </div>
          </div>
          <div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
      <div className="p2">
        <div className="heading">
          Unleash the potential of payment aggregation with our services
        </div>
        <div className="heading2">
          We provide custom software solutions designed to enhance your payment
          aggregator platform.
        </div>
        <div className="cards">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-mobile-screen fa-2xl"></i>
                <h5 className="card-title">Mobile Payment Solutions</h5>
                <p className="card-text">
                  Streamline and streamline and enhance payment processes on
                  mobile devices, enabling seamless and secure transactions.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-chart-column fa-2xl"></i>
                <h5 className="card-title">Web Applications</h5>
                <p className="card-text">
                  Develop and deploy customized web-based applications that
                  optimize payment aggregation operations and user experiences.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-magnifying-glass-arrow-right fa-2xl"></i>
                <h5 className="card-title">API Development & Integration</h5>
                <p className="card-text">
                  Custom APIs to enable seamless communication between various
                  systems and enhance the functionality of payment aggregators.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-location-pin-lock fa-2xl"></i>
                <h5 className="card-title">Secure Payment Integration</h5>
                <p className="card-text">
                  Secure and reliable integration of payment systems into your
                  platform, prioritizing data protection and transaction
                  integrity.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-chart-pie fa-2xl"></i>
                <h5 className="card-title">White Label Solutions</h5>
                <p className="card-text">
                  Customize and rebrand payment aggregation solutions as your
                  own, enabling you to offer a branded payment experience to
                  your customers.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-mars-stroke-right fa-2xl"></i>
                <h5 className="card-title">Third-Party Integrations</h5>
                <p className="card-text">
                  Seamlessly integrate third-party services and platforms into
                  your payment aggregation ecosystem, expanding functionality
                  and enabling a wider range of services.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-arrow-up-wide-short fa-2xl"></i>
                <h5 className="card-title">Analysis & Reporting</h5>
                <p className="card-text">
                  Implement robust tokenization and encryption techniques to
                  safeguard sensitive payment data, enhancing security and
                  compliance.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <i class="fa-solid fa-stethoscope fa-2xl"></i>
                <h5 className="card-title">Consulting & Technical Support</h5>
                <p className="card-text">
                  Expert consultation and technical support services to guide
                  and assist you optimizing the payment aggregation operations
                  and addressing any technical challenges you may encounter.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p3">
        <div className="heading">
          Ready to level up your payment aggregation game?
        </div>
        <div className="heading2">We're just a phone call away!</div>
        <div>
          <Link to="/contact" type="button" className="btn btn-primary">
            SCHEDULE A CALL
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Services;
