import React, { useEffect } from "react";
import img from "../images/MicrosoftTeams-image (2). (1).webp";
import { ContactUs } from "../components/ContactUs";
//import ContactForm from "../components/ContactForm";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact - NDDEO";
  }, []);
  return (
    <div className="contact">
      <div className="contact-body">
        <div className="c1">
          <div className="head">
            Two calls,
            <br />
            That's it!
          </div>
          <div className="head2">No beating around the bush</div>
          <div>
            <p className="para">
              We believe in clear and straightforward communication. If you
              believe that our services can help you unlock the full potential
              of your business, we would love to hear from you. During our
              initial call, we will understand your requirements, and in our
              follow-up call, we will provide you with tailored solutions that
              address your needs.
            </p>
            <p className="para">
              Let's collaborate to build solutions that drive tangible results
              and create a lasting impact for your business.
            </p>
          </div>
        </div>
        <div className="c2">
          <img className="c-img" src={img} alt="" />
        </div>
      </div>
      <div className="form">
        <ContactUs />
      </div>
    </div>
  );
};

export default Contact;
