import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer1">
        <div className="f1">
          <Link className="h_f link-no-underline" to="./">
            NDDEO
          </Link>
          <Link className="link-no-underline" to="./services">
            Services
          </Link>
          <Link className="link-no-underline" to="./careers">
            Careers
          </Link>
          <Link className="link-no-underline" to="./why_us">
            Why Us{" "}
          </Link>
        </div>
        <div className="f2">
          <Link className="h_f link-no-underline" to="./services">
            Services
          </Link>
          <div>Mobile Payment</div>
          <div>API Integration</div>
          <div>Consulting & Technical Support</div>
        </div>
        <div className="f3">
          <div className="h_f">Contact</div>
          <div>Mob : +91-8208450074</div>
          <div>
            <a
              className="link-no-underline"
              href="https://www.google.co.in/maps/place/21%C2%B022'33.4%22N+79%C2%B043'59.8%22E/@21.3759444,79.7332778,17z/data=!3m1!4b1!4m4!3m3!8m2!3d21.3759444!4d79.7332778?hl=en&entry=ttu"
            >
              1217, Shriram Nagar, B-601/R11 <br /> Bhandara, Maharashtra 441912
            </a>
          </div>
          <div>
            <a className="link-no-underline" href="mailto:admin@nddeo.com">
              admin@nddeo.com
            </a>
          </div>
        </div>
        <div className="f4">
          <div className="h_f">Follow Us</div>
          <div className="f4a">
            <div>
              <a className="link-no-underline" href="#">
                <i class="fab fa-linkedin fa-2x"></i>
              </a>
            </div>
            <div>
              <a
                className="link-no-underline"
                href="https://www.facebook.com/profile.php?id=100093349763664
            "
              >
                <i class="fab fa-facebook fa-2x"></i>
              </a>
            </div>
            <div>
              <a className="link-no-underline" href="#">
                <i class="fab fa-twitter fa-2x"></i>
              </a>
            </div>
            <div>
              <a
                className="link-no-underline"
                href="https://www.instagram.com/nddeo_info_solution/"
              >
                <i class="fab fa-instagram fa-2x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer2">
        © 2023{" "}
        <Link className="" to="./">
          NDDEO INFO SOLUTIONS
        </Link>{" "}
        All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
