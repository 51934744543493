import React, { useEffect } from "react";
import img from "../images/MicrosoftTeams-image (3). (1).webp";
import { Link } from "react-router-dom";

const WhyUs = () => {
  useEffect(() => {
    document.title = "Why Us - NDDEO";
  }, []);
  return (
    <div className="why">
      <div className="w-body">
        <div className="w1">
          <img className="w-img" src={img} alt="" />
        </div>
        <div className="w2">
          <div className="heading">
            Experience unwavering quality:
            <br />
            Trusted by industry leaders with their needs
          </div>
          <div className="w-heading2">
            <p>
              When industry leaders entrust us with their payment integration
              needs, it speaks volumes about the unparalleled quality we
              deliver. At NDDEO, we take immense pride in exceeding expectations
              and meeting the rigorous standards set by industry titans. With
              our expertise, your project will be meticulously executed with
              precision and finesse, ensuring seamless payment integrations that
              drive your success. Join our esteemed clientele today and
              experience the transformative power of unrivaled quality in every
              aspect of your payment aggregation journey.
            </p>
          </div>
        </div>
      </div>
      <div className="p3">
        <div className="heading">
          Ready to level up your payment aggregation game?
        </div>
        <div className="heading2">We're just a phone call away!</div>
        <div>
          <Link to="/contact" type="button" className="btn btn-primary">
            SCHEDULE A CALL
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
