import React, { useState } from "react";
import logo from "../images/NDDEO-Logo.png";
import { Link } from "react-router-dom";
import ham from "../images/ham.png";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="./">
            <img className="logo" src={logo} alt="NDDEO" height={65} />
          </Link>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img className="logo" src={ham} alt="=" height={30} />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/why_us">Why Us ?</Link>
            </li>
            <li>
              <Link to="/faqs">FAQs</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
