import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7wsr504",
        "template_79x0tdu",
        form.current,
        "Uon5gwqVVRgG2PsMN"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message Sent Successfully");
        },
        (error) => {
          console.log(error.text);
          toast.error("Something Went Wrong");
        }
      );
  };

  return (
    <div className="form1">
      <form ref={form} onSubmit={sendEmail}>
        <input
          type="text"
          name="user_name"
          id="name"
          placeholder="Enter Your Name...."
        />
        <input
          type="text"
          name="user_email"
          id="email"
          placeholder="Enter Your Email..."
        />
        <input
          type="text"
          name="user_contact"
          id="contact"
          placeholder="Enter Your Contact Number..."
        />
        <input type="text" id="subject" placeholder="Enter Your Subject..." />
        <textarea
          name="message"
          id="message"
          placeholder="Enter Your Message..."
          style={{ height: "200px" }}
        ></textarea>
        <input type="submit" className="btn btn-primary" value="Send" />
      </form>
    </div>
  );
};
