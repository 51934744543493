import React, { useState, useEffect } from "react";

const FAQs = () => {
  useEffect(() => {
    document.title = "FAQs - NDDEO";
  }, []);

  const [div1, setDiv1] = useState(false);
  const [div2, setDiv2] = useState(false);
  const [div3, setDiv3] = useState(false);
  const [div4, setDiv4] = useState(false);
  const [div5, setDiv5] = useState(false);
  const [div6, setDiv6] = useState(false);
  const [div7, setDiv7] = useState(false);
  const [div8, setDiv8] = useState(false);
  const [div9, setDiv9] = useState(false);
  const [div10, setDiv10] = useState(false);

  const handleDiv1Click = () => {
    setDiv1(!div1);
  };
  const handleDiv2Click = () => {
    setDiv2(!div2);
  };
  const handleDiv3Click = () => {
    setDiv3(!div3);
  };
  const handleDiv4Click = () => {
    setDiv4(!div4);
  };
  const handleDiv5Click = () => {
    setDiv5(!div5);
  };
  const handleDiv6Click = () => {
    setDiv6(!div6);
  };
  const handleDiv7Click = () => {
    setDiv7(!div7);
  };
  const handleDiv8Click = () => {
    setDiv8(!div8);
  };
  const handleDiv9Click = () => {
    setDiv9(!div9);
  };
  const handleDiv10Click = () => {
    setDiv10(!div10);
  };

  return (
    <div className="faqs">
      <div className="f-body">
        <div className="fa1">
          <div className="f-head">
            Unleash the potential of payment aggregation with our services
          </div>
          <div className="f-head2">
            Looking for the perfect payment aggregation solution? Look no
            further! Our custom software solutions provide the ideal blend of
            efficiency and flexibility.
          </div>
        </div>
      </div>
      <div className="q-body">
        <div className="q-head">Frequently Asked Questions</div>
      </div>
      <div className="q-ques">
        <div className="q">
          <div className="que" onClick={handleDiv1Click}>
            ▶️ What are mobile payment solutions?
          </div>
          {div1 === true ? (
            <div className="ans">
              ⏩ Mobile payment solutions are technologies that enable users to
              make secure and convenient payments using their mobile devices.
              These solutions streamline and enhance the payment process on
              mobile platforms, offering seamless and secure transactions
              through various methods such as mobile wallets and QR code
              scanning.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv2Click}>
            ▶️ How do mobile payment solutions streamline and enhance payment
            processes?
          </div>
          {div2 === true ? (
            <div className="ans">
              ⏩ Mobile payment solutions optimize the payment experience by
              providing a user-friendly interface, quick transaction processing,
              and integrated security measures. They eliminate the need for
              physical cards or cash, allowing customers to make payments
              anytime, anywhere, and improving efficiency for businesses.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv3Click}>
            ▶️ What are web applications in the context of payment aggregation?
          </div>
          {div3 === true ? (
            <div className="ans">
              ⏩ Web applications in payment aggregation refer to customized
              software applications developed and deployed on the web to
              optimize payment aggregation operations and enhance user
              experiences. These applications can include features such as
              transaction monitoring, reporting, and customer management, all
              accessible through a web browser.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv4Click}>
            ▶️ How does API development and integration contribute to payment
            aggregation?
          </div>
          {div4 === true ? (
            <div className="ans">
              ⏩ API (Application Programming Interface) development and
              integration facilitate seamless communication between different
              systems, enabling the functionality of payment aggregators. Custom
              APIs are developed to connect various platforms, applications, or
              services, allowing smooth data exchange and enabling features such
              as transaction processing, reporting, or authentication.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv5Click}>
            ▶️ What is secure payment integration?
          </div>
          {div5 === true ? (
            <div className="ans">
              ⏩ Secure payment integration refers to the process of integrating
              payment systems into a platform while prioritizing data protection
              and transaction integrity. This involves implementing robust
              security measures, such as encryption and tokenization, to
              safeguard sensitive payment data and prevent unauthorized access
              or fraud.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv6Click}>
            ▶️ What are white label solutions in payment aggregation?
          </div>
          {div6 === true ? (
            <div className="ans">
              ⏩ White label solutions in payment aggregation allow businesses
              to customize and rebrand existing payment aggregation solutions as
              their own. This enables them to offer a branded payment experience
              to their customers without the need for extensive development or
              infrastructure setup.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv7Click}>
            ▶️ How do third-party integrations benefit payment aggregation?
          </div>
          {div7 === true ? (
            <div className="ans">
              ⏩ Third-party integrations expand the functionality of payment
              aggregation platforms by seamlessly integrating additional
              services or platforms. This enables businesses to offer a wider
              range of services to their customers, such as loyalty programs,
              alternative payment methods, or additional financial services.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv8Click}>
            ▶️ How does analysis and reporting enhance payment aggregation
            operations?
          </div>
          {div8 === true ? (
            <div className="ans">
              ⏩ Analysis and reporting tools in payment aggregation provide
              insights into transaction data, customer behavior, and overall
              performance. By implementing robust tokenization and encryption
              techniques, payment data can be safeguarded while generating
              meaningful reports, facilitating better decision-making, and
              ensuring compliance with industry regulations.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv9Click}>
            ▶️ What consulting and technical support services are available for
            payment aggregation?
          </div>
          {div9 === true ? (
            <div className="ans">
              ⏩ Consulting and technical support services provide expert
              guidance and assistance in optimizing payment aggregation
              operations. They offer consultation on industry best practices,
              help address technical challenges, and ensure smooth
              implementation and ongoing support for payment aggregation
              solutions.
            </div>
          ) : null}
        </div>
        <div className="q">
          <div className="que" onClick={handleDiv10Click}>
            ▶️ How can I level up my payment aggregation game?
          </div>
          {div10 === true ? (
            <div className="ans">
              ⏩ To level up your payment aggregation game, consider exploring
              the mobile payment solutions, developing customized web
              applications, integrating APIs, ensuring secure payment
              integration, leveraging white label solutions, incorporating
              third-party integrations, utilizing analysis and reporting tools,
              and seeking consulting and technical support services to optimize
              your payment aggregation operations.
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
