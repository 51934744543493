import React, { useEffect } from "react";
import img from "../images/MicrosoftTeams-image. (1).webp";

const Careers = () => {
  useEffect(() => {
    document.title = "Careers - NDDEO";
  }, []);
  return (
    <div className="careers">
      <div className="ca-body">
        <div className="ca1">
          Ignite your career in payment aggregation with us
        </div>
        <div className="ca2">
          <img className="ca-img" src={img} alt="" />
        </div>
      </div>
      <div className="ca-body">
        <p className="ca-para">
          We're not just building a company; we're building a community of
          passionate individuals who are driving innovation in the payment
          aggregation industry. Join our dynamic team where collaboration,
          creativity, and growth opportunities are at the heart of everything we
          do. <br /> <br />
          Unleash your potential and explore exciting career openings. If you
          don't find any relevant openings but believe you can be a valuable
          addition to our journey, feel free to drop your resume at{" "}
          <a href="mailto:hr@nddeo.com">hr@nddeo.com</a>. Rest assured, you will
          hear back from us soon.
        </p>
      </div>
    </div>
  );
};

export default Careers;
